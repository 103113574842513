<template>
  <v-sheet
    id="install"
    class="sheet fill-height"
    height="100%"
  >
    <v-sheet
      v-if="android||ios"
      height="100%"
      class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-8"
    >
      <img 
        src="@/assets/start-install-icon.svg"
        class="empty-icon mx-auto"
      >
      <h4 class="title text-h6 mt-12">
        Instale nosso app
      </h4>
      <p 
        v-if="android" 
        class="text text-body-1 my-8"
      >
        Clique no botão abaixo para instalar o aplicativo Mobees para Motoristas e continuar o processo seletivo.
      </p>
      <p 
        v-else
        class="text text-body-1 my-8"
      >
        Siga a instrução abaixo para instalar o aplicativo Mobees para Motoristas. Em seguida abra o aplicativo através da tela inicial do seu iPhone.
      </p>
      <v-btn 
        v-if="android"
        large
        color="primary"
        class="btn-install grey--text text--darken-4 mt-4"
        @click="installApp"
      >
        Instalar
      </v-btn> 
      <v-bottom-sheet 
        v-else-if="ios"
        v-model="ios"
        persistent
        hide-overlay
        inset
      >
        <v-sheet 
          v-if="safari"
          class="pa-4 my-4 mx-auto text-center" 
          max-width="320px"
        >
          Aperte em <v-icon>{{ icons.mdiExportVariant }}</v-icon> e depois selecione <b class="text-no-wrap">"Adicionar à Tela de Início"</b> <v-icon>{{ icons.mdiPlusBox }}</v-icon>
        </v-sheet>
        <v-sheet 
          v-else
          class="pa-4 my-4 mx-auto text-center" 
          max-width="320px"
        >
          Acesse esta página <b>parceiros.mobees.com.br pelo navegador Safari</b> para poder instalar nosso app.
        </v-sheet>
      </v-bottom-sheet>
    </v-sheet>
    <v-sheet
      v-else
      class="sheet d-flex flex-column justify-center flex-grow-1 text-center px-8 pb-8"
    >
      <img 
        src="@/assets/start-install-icon.svg"
        class="empty-icon mx-auto"
      >
      <h4 class="title text-h6 mt-12">Instale nosso app</h4>
      <p class="text text-body-1 my-8">
        Pré-cadastro realizado com sucesso! Acesse o link enviado para seu email pelo seu celular para continuar seu cadastro.
      </p>
      <v-btn 
        v-if="android"
        large
        color="primary"
        class="btn-install grey--text text--darken-4  mt-4"
        @click="installApp"
      >
        Baixar App
      </v-btn>  
    </v-sheet>
  </v-sheet>
</template>

<style>

  #install .empty-icon {
    max-width: 160px;
  }


</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiExportVariant,
    mdiPlusBox
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync } from 'vuex-pathify'
  import device from 'mobile-device-detect';


  export default {
    name: 'Install',

    props: {
      installed: {
        type: Boolean,
        default: true,
      },
      pwa: {
        type: Boolean,
        default: true,
      }
    },

    data: () => ({
      icons: {
        mdiClose,
        mdiExportVariant,
        mdiPlusBox
      },
      android: device.isAndroid,
      ios: device.isIOS,
      safari: device.isMobileSafari,
    }),

    computed: {
      toast: sync('app/toast'),

    },

    methods: {
      ...services,

      async installApp () {
        // link to stores 
        if (device.isAndroid) {
          location.href = 'https://play.google.com/store/apps/details?id=br.com.mobees.parceiro';
          // location.href = 'https://parceiros.stg.mobees.com.br/app-alpha-v2.apk'; // dev fallback
        }else if (device.isIOS) {
          // TODO update link
          // location.href = 'https://www.apple.com/app-store/parceiros-mobees';
        }
      },
    },

    created () {
      
    },

    mounted () {

    },

    directives: {
    },

  }
</script>
